$(document).ready(function () {
    JS.Responsive
        .addHorizontalSizePoint( 'x-small', 370 )
        .addHorizontalSizePoint( 'small', 480 )
        .addHorizontalSizePoint( 'medium', 768 )
        .addHorizontalSizePoint( 'x-medium', 960 )
        .addHorizontalSizePoint( 'x-large', 1010 )
        .addHorizontalSizePoint( 'large', 1160 );

       if (location.hash){
	        setTimeout(function(){
	        	if (location.hash == '#services') {
	        		$('html, body').animate({
					   scrollTop: $(location.hash).offset().top + 20
					});
	        	} else if (location.hash == '#our-promise'){
	        		$('html, body').animate({
					   scrollTop: $(location.hash).offset().top + 90
					});
	        	} else {
	        		$('html, body').animate({
					   scrollTop: $(location.hash).offset().top - 94
					});
	        	}
	        }, 0);
	    }

	function mobileMenu() {
        // variables
        var $mainMenu = $('#menu');

        // mobile
        if ($('html').is('.x-large-less') && !$mainMenu.siblings('.hamburger').length) {
            // pridanie hamburgera
            $mainMenu.before('<div class="hamburger"><span class="line" /><span class="line" /><span class="line" /></div>');

            // pridanie mobilneho menu
            $('body').append('<div class="mobile-menu"> \
							  		<div class="scroll"> \
										<div class="menu-container"> \
											<div class="menu-wrapper"> \
											</div> \
										</div> \
									</div> \
							  </div>');
            // polozky z menu do mobilneho menu
            $mainMenu.clone().appendTo('.mobile-menu .menu-wrapper');
        }

        // desktop
        if ( $('html').is('.x-large-more') && $mainMenu.siblings('.hamburger').length ) {
            // odstranenie hamburgera
            $mainMenu.siblings('.hamburger').remove();
            // odstranenie mobilneho menu
            $('.mobile-menu').remove();
            // posunut web naspat
            $('.page').removeClass('shiftleft');
        }
    }

    // presuvam aktivny language na prve miesto
    //$('#language li a.active').parent().prependTo('#language ul');


    // SLICK
    $('#references .references').slick({
    	slidesToShow: 3,
    	slidesToScroll: 3,
    	dots: true,
    	arrows: false,
    	autoplay: true,
  		autoplaySpeed: 3500,
    	responsive: [
    		{
		      breakpoint: 900,
		      settings: {
		        slidesToShow: 2,
		        slidesToScroll: 2
		      }
		    },
		    {
		      breakpoint: 620,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1
		      }
		    }
	    ]
    });

    function slickContent() {
    	if($('html').is('.medium-less')) {
    		// pri resizovani zrusime existujuci slick aby sa pregeneroval znovu kvoli sirku
			if ($('#services .row').hasClass('slick-slider')) {
				$('#services .row').slick('unslick');
			}
			$('#services .row').slick();
    	} else {
    		// ak ideme z mobilu do desktopu tak zrusime slick slider
			if ($('#services .row').hasClass('slick-slider')) {
				$('#services .row').slick('unslick');
			}
    	}

    	if ($('body').is('.page-homepage')) {
    		if ($('html').is('.x-large-less')) {
	    		if ($('#news .row').hasClass('slick-slider')) {
					$('#news .row').slick('unslick');
				}
				$('#news .row').slick({
					slidesToShow: 2,
					slidesToScroll: 2,
			    	dots: true,
			    	arrows: false,
			    	responsive: [
				    {
				      breakpoint: 768,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1
				      }
				    }
			    ]
				});
	    	} else {
	    		// ak ideme z mobilu do desktopu tak zrusime slick slider
				if ($('#news .row').hasClass('slick-slider')) {
					$('#news .row').slick('unslick');
				}
	    	}
    	}
    }

    if (!$('body').is('.page-homepage')) {
    	$('#menu .menu-links li a.active').removeClass('active');
    }

    if ($('body').is('.page-homepage')) {
    	$('#layout-header #menu .menu-links a.scroll').on('click', function (e) {
	    	e.preventDefault();
	    	var l = $(this),
	    		lid = this.hash.substr(1);
	    		console.log(lid);
	    	if (lid == 'our-promise') {
	    		console.log($('#our-promise .name').offset().top);
	    		$("html, body").stop().animate({scrollTop:$('#our-promise .name').offset().top-600},1250,"easeInOutExpo");
	    	} else if (lid == 'services') {
	    		$("html, body").stop().animate({scrollTop:$('#services').offset().top+20},1250,"easeInOutExpo");
	    	} else {
	    		$("html, body").stop().animate({scrollTop:$('#' + lid).offset().top-94},1250,"easeInOutExpo");
	    	}
		});

		$(document).on('click', '.mobile-menu #menu .menu-links a.scroll', function (e) {
	    	e.preventDefault();
	    	$('body').removeClass('menu-open');
	    	$(this).parent().siblings().addClass('hide');
	    	$('.mobile-menu #language').addClass('hide');

	    	setTimeout(
	    		function() {
	    			$('.mobile-menu').removeClass('open');
	    			$('.hamburger').removeClass('is-active');
	    			setTimeout(function(){$('.mobile-menu #menu li, .mobile-menu #language').removeClass('hide');}, 200);
			}, 1000);



		    var  lid = this.hash.substr(1),
		   		targetLink = $(this).attr('href');
		    $('html, body').animate({
		      scrollTop: $('#' + lid).offset().top - 94
		    }, 0);
		});

		$(document).on("scroll", onScroll);

		// $(document).on('click', '.mobile-menu #menu a.scroll', function(e){
	 //    	e.preventDefault();
	 //    	$('body').removeClass('menu-open');
	 //    	$(this).parent().siblings().addClass('hide');
	 //    	$('.mobile-menu #language').addClass('hide');

	 //    	setTimeout(
	 //    		function() {
	 //    			$('.mobile-menu').removeClass('open');
	 //    			$('.hamburger').removeClass('is-active');
	 //    			setTimeout(function(){$('.mobile-menu #menu li, .mobile-menu #language').removeClass('hide');}, 200);
		// 	}, 1000);
	 //    });
    }
    

	// activne linky podla toho na ktorej sekcii som zoscrollovany
    function onScroll(event){
	    var scrollPos = $(document).scrollTop() + 95;
	    $('#layout-header #menu .menu-links a.scroll').each(function () {
	        var currLink = $(this);
	        var refElement = $('#' + this.hash.substr(1));
	        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.outerHeight() > scrollPos) {
	            $('#layout-header #menu .menu-links a').removeClass("active");
	            currLink.addClass("active");
	        }
	        else{
	            currLink.removeClass("active");
	        }
		});
	}

    // spustit funkcie pri zmene breakpointu
    JS.Responsive.addOnChangeHadler( function(e){

        if ( e.changedSizePointHorizontal ) {
            mobileMenu();
            slickContent();
            setHeightColumn();
        }
    });
    mobileMenu();
    slickContent();

    // otvaranie / zatvaranie mobilneho menu
    $(document).on('click', '.hamburger', function() {
        $(this).toggleClass("is-active");
        $('.mobile-menu').toggleClass('open');
        $('body').toggleClass('menu-open');
    });

    // FIX GOOGLE MAPS SCROLL
	$('#map .overlay').on('click', function() {
		$(this).hide();
	});
	
	$(document).on('click', function(e) {
		var $tar = $(e.target);
		
		if($tar.closest('#map').attr('id') != "map")
			$('#map .overlay').show();
	});

	// ak som na stranke novinky, pridavam active class v menu
	if ($('body').is('.page-novinky')) {
		$('#menu a.novinky').addClass('active');
	}


	function setHeightColumn() {
		var maxHeight = 0;

		if($('html').is('.medium-more')) {
			$("#news article").each(function() {
				var divHeight = $(this).outerHeight(); 
				if (divHeight > maxHeight) {
					maxHeight = divHeight;
				}
			}).height(maxHeight);
		} else {
			$("#news article").height('auto');
		}
	}

	$(window).load(function() {
	    setHeightColumn();
	});


});	